import React, { Component } from 'react';

import { msalAuth } from '../msal/MsalAuthProvider';


export class Support extends Component {

    render() {
        return (
            <div className="container">
                <h1>Support</h1>
                <hr />
                <ul>
                    {/*<li>For technical issues, please visit and submit a ticket to: <a href="https://theprovidenciagroup.zendesk.com/">The Providencia Group Support Channel</a></li>*/}
                    <li>For technical issues, please visit submit a ticket to: <a href="https://support.theprovidenciagroup.com/hc/en-us/">Luna– PG Digital Support Hub (theprovidenciagroup.com) </a></li>

                </ul>

                <h1>Release Notes</h1>
                <hr />
                <ul>
                    {/*<li>For technical issues, please visit and submit a ticket to: <a href="https://theprovidenciagroup.zendesk.com/">The Providencia Group Support Channel</a></li>*/}
                    <li><a href="https://support.theprovidenciagroup.com/hc/en-us/sections/23254643748763-Luna-Announcements">Luna Announcements </a></li>

                </ul>
                <hr />

            </div>
        );
    }
}