import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent.js';
import DataGrid, { Column, Paging, FilterRow, Pager } from 'devextreme-react/ui/data-grid';
import FileUploader from 'devextreme-react/file-uploader';
import Button from 'devextreme-react/ui/button';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';

import { msalAuth } from '../../msal/MsalAuthProvider';
import { msalConfig } from '../../msal/MsalConfig';

export class FacilityImportContainer extends BaseComponent {

    render() {

        return (
            <div>
                <div className="container-fluid">
                    <h1>Import Facilities</h1>
                    <p>
                        <a href='Blank Template Facilities.xlsx' download>Blank Template Facilities.xlsx</a>
                    </p>
                    <h2>Step 1: Download a blank template</h2>
                    <ul>
                        <li>The 1st tab is the facility data for import</li>
                        <li>The 2nd tab provides guidance & instructions</li>
                        <li>The 3rd tab is an example of a facility import, highlighting required, optional, and active fields.</li>
                    </ul>
                    <h2>Step 2: Update and Save the facility import file</h2>
                    <ul>
                        <li>Add facilities to the "Luna facility Import" tab</li>
                        <li>Be sure to save the file before the next step</li>
                    </ul>
                    <h2>Step 3: Select the facility import file and upload for verification</h2>

                    <ul>
                        <li>Select the Import file, and click "Upload".</li>
                    </ul>
                    <div className="fileuploader-container">
                        <FileUploader ref={ref => this.fileUpload = ref} selectButtonText="Select Import File" labelText="" uploadUrl={`/API/Cases/BulkUpload`} name="file" onBeforeSend={this.onBeforeSend} onUploaded={this.onUploaded} uploadMode="useButtons" multiple={false} />
                    </div>

                    {/*{this.state.importResult &&*/}
                    {/*    <div>*/}
                    {/*        <p title={this.state.importErrorMessage}>{this.state.importMessage}</p>*/}
                    {/*        <br />*/}
                    {/*        <DataGrid dataSource={this.state.casesToImport} ref={ref => this.dataGrid = ref}*/}
                    {/*            showBorders={true} allowColumnResizing={true}>*/}
                    {/*            <FilterRow visible={true} />*/}
                    {/*            <Paging defaultPageSize={10} />*/}
                    {/*            <Pager showPageSizeSelector={true}*/}
                    {/*                allowedPageSizes={[5, 10, 20]}*/}
                    {/*                showInfo={true} />*/}
                    {/*            <Column dataField="willImport" caption="Will Import?" cellRender={function (options) { return (<img width='20px' height='20px' src={options.row.data.willImport ? 'yesbutton.png' : 'nobutton.png'} />); }} defaultFilterValue={false} />*/}
                    {/*            <Column dataField="aNumber" caption="A#*" cellRender={function (options) { return (<div title={options.row.data.aNumberMessage} style={{ backgroundColor: `${options.row.data.aNumberValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.aNumber}</div>); }} />*/}
                    {/*            <Column dataField="firstName" caption="First Name(s)*" cellRender={function (options) { return (<div title={options.row.data.firstNameMessage} style={{ backgroundColor: `${options.row.data.firstNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.firstName}</div>); }} />*/}
                    {/*            <Column dataField="middleName" caption="Middle Name(s)" cellRender={function (options) { return (<div title={options.row.data.middleNameMessage} style={{ backgroundColor: `${options.row.data.middleNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.middleName}</div>); }} />*/}
                    {/*            <Column dataField="lastName" caption="Last Name(s)*" cellRender={function (options) { return (<div title={options.row.data.lastNameMessage} style={{ backgroundColor: `${options.row.data.lastNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.lastName}</div>); }} />*/}
                    {/*            <Column dataField="dob" caption="DOB*" cellRender={function (options) { return (<div title={options.row.data.dobMessage} style={{ backgroundColor: `${options.row.data.dobValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.dob}</div>); }} />*/}
                    {/*            <Column dataField="sponsorCategory" caption="Sponsor CAT*" cellRender={function (options) { return (<div title={options.row.data.sponsorCategoryMessage} style={{ backgroundColor: `${options.row.data.sponsorCategoryValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorCategory}</div>); }} />*/}
                    {/*            <Column dataField="gender" caption="Gender*" cellRender={function (options) { return (<div title={options.row.data.genderMessage} style={{ backgroundColor: `${options.row.data.genderValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.gender}</div>); }} />*/}
                    {/*            <Column dataField="relationshipGroup" caption="Relationship Group" cellRender={function (options) { return (<div title={options.row.data.relationshipGroupMessage} style={{ backgroundColor: `${options.row.data.relationshipGroupValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.relationshipGroup}</div>); }} />*/}
                    {/*            <Column dataField="nationality" caption="Nationality" cellRender={function (options) { return (<div title={options.row.data.nationalityMessage} style={{ backgroundColor: `${options.row.data.nationalityValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.nationality}</div>); }} />*/}
                    {/*            <Column dataField="language" caption="Language" cellRender={function (options) { return (<div title={options.row.data.languageMessage} style={{ backgroundColor: `${options.row.data.languageValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.language}</div>); }} />*/}
                    {/*            <Column dataField="shelter" caption="Shelter*" cellRender={function (options) { return (<div title={options.row.data.shelterMessage} style={{ backgroundColor: `${options.row.data.shelterValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.shelter}</div>); }} />*/}
                    {/*            <Column dataField="caseStatus" cellRender={function (options) { return (<div title={options.row.data.caseStatusMessage} style={{ backgroundColor: `${options.row.data.caseStatusValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.caseStatus}</div>); }} />*/}
                    {/*            */}{/*<Column dataField="slName" caption="(SL Name)" cellRender={function (options) { return (<div title={options.row.data.slNameMessage} style={{ backgroundColor: `${options.row.data.slNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.slName}</div>); }} />*/}
                    {/*            <Column dataField="seniorLeadID" caption="Senior Lead ID" cellRender={function (options) { return (<div title={options.row.data.seniorLeadIDMessage} style={{ backgroundColor: `${options.row.data.seniorLeadIDValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.seniorLeadID}</div>); }} />*/}
                    {/*            */}{/*<Column dataField="lcmName" caption="(LCM Name)" cellRender={function (options) { return (<div title={options.row.data.lCMNameMessage} style={{ backgroundColor: `${options.row.data.lcmNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.lcmName}</div>); }} />*/}
                    {/*            <Column dataField="leadCaseManagerID" caption="Lead Case Manager ID" cellRender={function (options) { return (<div title={options.row.data.leadCaseManagerIDMessage} style={{ backgroundColor: `${options.row.data.leadCaseManagerIDValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.leadCaseManagerID}</div>); }} />*/}
                    {/*            */}{/*<Column dataField="cmName" caption="(CM Name)" cellRender={function (options) { return (<div title={options.row.data.cMNameMessage} style={{ backgroundColor: `${options.row.data.cmNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.cmName}</div>); }} />*/}
                    {/*            <Column dataField="caseManagerID" caption="Case Manager ID" cellRender={function (options) { return (<div title={options.row.data.caseManagerIDMessage} style={{ backgroundColor: `${options.row.data.caseManagerIDValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.caseManagerID}</div>); }} />*/}
                    {/*            */}{/*<Column dataField="shName" caption="(SH Name)" cellRender={function (options) { return (<div title={options.row.data.shNameMessage} style={{ backgroundColor: `${options.row.data.shNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.shName}</div>); }} />*/}
                    {/*            */}{/*<Column dataField="shadowID" caption="Shadow ID" cellRender={function (options) { return (<div title={options.row.data.shadowIDMessage} style={{ backgroundColor: `${options.row.data.shadowIDValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.shadowID}</div>); }} />*/}
                    {/*            */}{/*<Column dataField="desName" caption="(DES Name)" cellRender={function (options) { return (<div title={options.row.data.dESNameMessage} style={{ backgroundColor: `${options.row.data.desNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.desName}</div>); }} />*/}
                    {/*            <Column dataField="caseAids" caption="Support Staff ID" cellRender={function (options) { return (<div title={options.row.data.caseAidsMessage} style={{ backgroundColor: `${options.row.data.caseAidsValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.caseAids}</div>); }} />*/}
                    {/*            <Column dataField="arrivedAtShelterMilestone" caption="Arrived at Shelter" cellRender={function (options) { return (<div title={options.row.data.arrivedAtShelterMilestoneMessage} style={{ backgroundColor: `${options.row.data.arrivedAtShelterMilestoneValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.arrivedAtShelterMilestone}</div>); }} />*/}
                    {/*            <Column dataField="staffAssignedMilestone" caption="Staff Assigned" cellRender={function (options) { return (<div title={options.row.data.staffAssignedMilestoneMessage} style={{ backgroundColor: `${options.row.data.staffAssignedMilestoneValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.staffAssignedMilestone}</div>); }} />*/}
                    {/*            <Column dataField="initialIntakeAssessmentCompletedMilestone" caption="Initial Intake Assessment Completed" cellRender={function (options) { return (<div title={options.row.data.initialIntakeAssessmentCompletedMilestoneMessage} style={{ backgroundColor: `${options.row.data.initialIntakeAssessmentCompletedMilestoneValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.initialIntakeAssessmentCompletedMilestone}</div>); }} />*/}
                    {/*            <Column dataField="uacAssessmentCompletedMilestone" caption="UAC Assessment Completed" cellRender={function (options) { return (<div title={options.row.data.uacAssessmentCompletedMilestoneMessage} style={{ backgroundColor: `${options.row.data.uacAssessmentCompletedMilestoneValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.uacAssessmentCompletedMilestone}</div>); }} />*/}
                    {/*            <Column dataField="riskAssessmentCompletedMilestone" caption="Risk Assessment Completed" cellRender={function (options) { return (<div title={options.row.data.riskAssessmentCompletedMilestoneMessage} style={{ backgroundColor: `${options.row.data.riskAssessmentCompletedMilestoneValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.riskAssessmentCompletedMilestone}</div>); }} />*/}
                    {/*            */}{/*<Column dataField="religiousAffiliation" caption="Religious Affiliation" cellRender={function (options) { return (<div title={options.row.data.religiousAffiliationMessage} style={{ backgroundColor: `${options.row.data.religiousAffiliationValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.religiousAffiliation}</div>); }} />*/}
                    {/*            <Column dataField="podNumber" caption="Pod Number" cellRender={function (options) { return (<div title={options.row.data.podNumberMessage} style={{ backgroundColor: `${options.row.data.podNumberValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.podNumber}</div>); }} />*/}
                    {/*            <Column dataField="bedNumber" caption="Bed Number" cellRender={function (options) { return (<div title={options.row.data.bedNumberMessage} style={{ backgroundColor: `${options.row.data.bedNumberValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.bedNumber}</div>); }} />*/}
                    {/*        </DataGrid>*/}

                    {/*    </div>*/}
                    {/*}*/}
                    <br />
                    {/*{this.state.invalidCounts && Object.keys(this.state.invalidCounts).length != 0 &&*/}
                    {/*    <div>*/}
                    {/*        <p>Error count per column</p>*/}
                    {/*        <ul>*/}
                    {/*            {Object.entries(this.state.invalidCounts).map(([key, value]) => {*/}
                    {/*                return <li key={key}><b>{key}:</b> {value}</li>*/}
                    {/*            })}*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    {/*{this.state.importCount > 0 &&*/}
                    {/*    <div>*/}
                    {/*        <h2>Step 4: After verification and review, click import to load the cases into Luna</h2>*/}
                    {/*        <ul>*/}
                    {/*            <li>After reviewing the validation results, import Cases in the file. If there are errors, fix and re-upload the file.</li>*/}
                    {/*        </ul>*/}
                    {/*        <Button className="button" text="Import Cases in the File" type="success" onClick={this.ImportRecords} />*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
                <hr />
            </div>
        );

    }

}