
import React from 'react';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent';
import DataGrid, { FilterRow, Column, Pager, Paging } from 'devextreme-react/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import Button from 'devextreme-react/ui/button';
import { Link } from 'react-router-dom';
import Form, { Item, Label } from 'devextreme-react/form';

import { msalAuth } from '../../msal/MsalAuthProvider';

export class MyCasesContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.formElement = React.createRef();

        this.state = {
            loading: false,
            userIsSysAdmin: false,
            userId: 0,

            filters: {
                active: true,
                covidQuarantine: false,
                closed: false,
                insufficientFunds: false,
                exitWithoutTravel: false,
                noSponsor: false,
                voluntaryDeparture: false,
                referredOEM: false,
                searchText: ''
            }
        };
    }

    customDataSource = new CustomStore({
        key: 'id',
        onLoading: (loadOptions) => {
            console.log('Loading: ' + loadOptions);

            loadOptions.userData["active"] = this.state.filters.active;
            loadOptions.userData["covidQuarantine"] = this.state.filters.covidQuarantine;
            loadOptions.userData["closed"] = this.state.filters.closed;
            loadOptions.userData["insufficientFunds"] = this.state.filters.insufficientFunds;
            loadOptions.userData["exitWithoutTravel"] = this.state.filters.exitWithoutTravel;
            loadOptions.userData["noSponsor"] = this.state.filters.noSponsor;
            loadOptions.userData["referredOEM"] = this.state.filters.referredOEM;
            loadOptions.userData["voluntaryDeparture"] = this.state.filters.voluntaryDeparture;

            loadOptions.userData["text"] = this.state.filters.searchText;
        },
        load: (loadOptions) => {
            console.log('Load', loadOptions);

            var queryString = ' ';
            var queryOptions = Object.keys(loadOptions.userData);

            console.log(queryOptions);

            for (var i = 0; i < queryOptions.length; i++) {

                if (queryString) {
                    queryString += '&';
                }

                queryString += queryOptions[i] + '=' + loadOptions.userData[queryOptions[i]];
            }
            console.log(queryString);

            return FetchAPIPromise('Cases/GetMy', queryString);
        }
    });

    SearchCases = (e) => {
        this.dataGrid.instance.refresh();
    }

    GetData = async () => {
        var userRoles = msalAuth.getAccount().idTokenClaims.roles;
        var isUserSystemAdmin = userRoles.findIndex(element => element === 'SystemAdmin') > -1;

        await this.setState({
            userIsSysAdmin: isUserSystemAdmin
        });
    }

    //componentDidMount = async () => {
    //    var employeeData = await this.FetchAPI('Employee/GetEmployeeByEmail/' + msalAuth.getAccount().userName);
    //    console.log(employeeData.employeeId + " Testing");

    //    await this.setState({
    //        userId: employeeData.employeeId
    //    });
    //}

    render() {

        return (
            <div className="container-fluid">
                <h1>My Cases</h1>
                <div>
                    <Link to="/create-case">
                        <Button icon="plus" text="Add New Case" />
                    </Link>
                    {/*&nbsp;*/}
                    {/*<Link to="/import-cases">*/}
                    {/*    <Button icon text="Import Cases" />*/}
                    {/*</Link>*/}
                    <hr />
                </div>

                <Form
                    ref={ref => this.editFormControl = ref}
                    formData={this.state.filters} colCount={3}>
                    <Item itemType="group" colCount={5} colSpan={2}>
                        <Item dataField="active" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }} />
                        <Item dataField="covidQuarantine" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }} />
                        <Item dataField="closed" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }} />
                        <Item dataField="insufficientFunds" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }}>
                            <Label text="Referred to NGO" />
                        </Item>
                        <Item dataField="referredOEM" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }}>
                            <Label text="Referred to OEM" />
                        </Item>
                        <Item dataField="exitWithoutTravel" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }}>
                            <Label text="Exit with Future Travel" />
                        </Item>
                        <Item dataField="noSponsor" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }} />
                        <Item dataField="voluntaryDeparture" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }} />
                    </Item>
                    <Item colSpan={1} dataField="searchText" editorType="dxTextBox" editorOptions={{ placeholder: 'Search for Case#, A#, EPS#, Alternative ID, First Name, or Last Name', onEnterKey: this.SearchCases, showClearButton: true }}>
                        <Label visible={false} />
                    </Item>
                </Form>
                <br />

                <DataGrid dataSource={this.customDataSource} ref={ref => this.dataGrid = ref}
                    showBorders={true} allowColumnResizing={true}
                    showRowLines={true} rowAlternationEnabled={true}>
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />

                    <Column dataField="caseNumber" cellRender={function (options) { return (<Link to={`/case-information/${options.row.data.id}`}>{options.value}</Link>); }} />
                    <Column dataField="aNumber" caption="A Number" />
                    <Column dataField="firstName" />
                    <Column dataField="lastName" />
                    <Column dataField="dob" caption="DOB" format="MM/d/yyyy" dataType="date" />
                    <Column dataField="createDate" caption="Case Create Date" format="MM/d/yyyy" dataType="date" />
                    <Column dataField="caseStatusName" caption="Case Status" />
                    <Column dataField="caseManagerDisplayName" caption="Intake Specialist" />
                    <Column dataField="seniorLeadDisplayName" caption="Travel Specialist" />
                    <Column dataField="facilityName" caption="Facility" />
                </DataGrid>

                {/*{this.state.userId === 0 &&*/}
                {/*    <footer style={{ backgroundColor: "yellow", width: "100%", justifyContent: 'center' }}>*/}
                {/*        <div>*/}
                {/*            <h1>User is not in database</h1>*/}
                {/*        </div>*/}
                {/*    </footer>*/}
                {/*}*/}
            </div>
        );
    }
}