import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';
import { msalAuth } from '../msal/MsalAuthProvider';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        var userRoles = msalAuth.getAccount().idTokenClaims.roles;

        var host = window.location.hostname;
        var environmentIndicator;
        var environmentName;

        if (host == 'luna.theprovidenciagroup.com') {
            environmentIndicator = '#75C7B1';
            environmentName = '';
        } else if (host == 'janus.theprovidenciagroup.com') {
            environmentIndicator = 'orange';
            environmentName = '(Janus)';
        } else if (host == 'ceres.theprovidenciagroup.com') {
            environmentIndicator = 'orange';
            environmentName = '(Ceres)';
        } else if (host == '127.0.0.1') {
            environmentIndicator = '#FFD5FA';
            environmentName = '(Dev)';
        } else {
            environmentIndicator = 'red';
            environmentName = '(Unknown)';
        }

        return (
            <header className="navbar fixed-top" style={{ backgroundColor: environmentIndicator, justifyContent: 'center' }}>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">Luna { environmentName }</NavbarBrand>

                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />

                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                {userRoles.find(element => element === 'SuperAdmin') &&
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle className='Alt-DropdownMenu' caret size="md">
                                            Super Admin
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/release-notes">Release Notes</NavLink>
                                            </NavItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                }
                                {userRoles.find(element => element === 'SystemAdmin') &&
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle className="Alt-DropdownMenu" caret size="md">
                                            Admin
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/employees">Employees</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/facilities">Facilities</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/languages">Languages</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/nationalities">Nationalities</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/destinationMetros">Destination Metros</NavLink>
                                            </NavItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                }
                                {userRoles.find(element => element === 'CaseManager') &&
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/cases">Case List</NavLink>
                                    </NavItem>
                                }
                                {/*{userRoles.find(element => element === 'CaseManager') &&*/}
                                {/*    <NavItem>*/}
                                {/*        <NavLink tag={Link} className="text-dark" to="/cases-dashboard">Case Dashboard</NavLink>*/}
                                {/*    </NavItem>*/}
                                {/*}*/}
                                {userRoles.find(element => element === 'CaseManager') &&
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/my-cases">My Cases</NavLink>
                                    </NavItem>
                                }
                                <NavItem>
                                    <Link tag={Link} className="text-dark nav-link" target="_blank" to={{ pathname: "https://app.powerbi.com/groups/me/apps/3876da5a-cb2c-4ec8-a2f3-5186dc9158a0/reports/45b2d9a8-7cde-431b-9262-7678bf692e63/ReportSection8428c48d542862bd2013" }}>Reporting</Link>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/support">Support</NavLink>
                                </NavItem>
                                {/*<LoginMenu />*/}
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}