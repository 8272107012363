
import React from 'react';
import BaseComponent from '../BaseComponent.js';

import Scheduler from 'devextreme-react/scheduler';
import TagBox from 'devextreme-react/tag-box';

export class CaseScheduleContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    text: 'Fingerprint Capture Deadline',
                    startDate: new Date(2020, 6, 18, 9, 30),
                    endDate: new Date(2020, 6, 18, 9, 30)
                },
                {
                    text: 'UAC Length of Stay Limit',
                    startDate: new Date(2020, 6, 7, 9, 30),
                    endDate: new Date(2020, 6, 7, 9, 30)
                },
                {
                    text: 'Conduct Interview',
                    startDate: new Date(2020, 6, 22, 9, 30),
                    endDate: new Date(2020, 6, 22, 11, 30)
                }
            ],
            caseManagers: [
                'Adam Graham',
                'Alex Bechtlufft',
                'Jose Ramirez'
            ],
            loading: false
        };
    }

    render() {
        const currentDate = new Date(2020, 6, 17);
        const views = ['day', 'week', 'workWeek', 'month'];

        return (
            <div className="container">
                <h1>Case Management Schedule</h1>

                <TagBox items={this.state.caseManagers}
                    showSelectionControls={true}
                    placeholder="Showing All Case Managers"
                    applyValueMode="useButtons" />

                <br />
                <Scheduler
                    dataSource={this.state.data}
                    views={views}
                    defaultCurrentView="month"
                    defaultCurrentDate={currentDate}
                    height={600}
                    startDayHour={9} />
                <br />
            </div>
        );
    }
}