import React from 'react';
import BaseComponent from '../BaseComponent.js';
import DataGrid, { Column, Paging, FilterRow, Pager } from 'devextreme-react/ui/data-grid';
import FileUploader from 'devextreme-react/file-uploader';
import Button from 'devextreme-react/ui/button';

import { msalAuth } from '../../msal/MsalAuthProvider';
import { msalConfig } from '../../msal/MsalConfig';

export class ReleaseNotesImportContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            token: '',
            importResult: null,
            importMessage: null,
            releaseNotesToImport: [],
            importCount: null
        };
    }

    GetData = async () => {
        console.log('get data');

        var requestScope = msalConfig.clientId + '/.default';

        const accessTokenRequest = {
            scopes: [requestScope]
        }

        var token = await msalAuth.acquireTokenSilent(accessTokenRequest)

        console.log(token.accessToken);

        this.setState({
            token: token.accessToken
        });
    }

    ImportRecords = async (e) => {
        await this.setState({
            loading: true
        });

        try {
            var recordsToImport = this.state.releaseNotesToImport.filter((item) => item.willImport);

            const result = await this.PostAPI('ReleaseNotes/BulkImport', recordsToImport);

            if (result.status == 1) {
                alert(result.message);

                this.props.history.push('/');
                this.props.history.push('/release-Notes');
            }
            else {
                alert('Error encountered during import.');
            }
        }
        finally {

        }
    }

    onBeforeSend = (e) => {
        console.log(e);

        e.request.setRequestHeader('Authorization', `Bearer ${this.state.token}`);
    }

    onUploaded = (e) => {
        console.log('Uploaded', e);

        var response = JSON.parse(e.request.response);

        this.setState({
            importResult: response.status,
            importMessage: response.message,
            importErrorMessage: response.errorMessage,
            releaseNotesToImport: response.releaseNotes,
            importCount: response.willImportCountReleaseNotes
        });

        this.fileUpload.instance.reset();
    }

    render() {

        let id = 0;
        function createData(type, info) {
            id += 1;
            return { id, type, info };
        }

        function NewLineText(props) {
            const text = props;
            const newLine = text.split('\n').map(str => <div>{str}</div>);
            return newLine;
        }

        function createTable(tableData, header1, header2) {
            return (
                <div>
                    <table>
                        <tr key={0}>
                            <th>{header1}</th>
                            <th>{header2}</th>
                        </tr>
                        {tableData.map(row => (
                            <tr key={row.id}>
                                <td>{row.type}</td>
                                <td>{row.info}</td>
                            </tr>
                        ))}
                    </table >
                </div>
            )
        }

        let requiredFieldsRows = [
            createData('Work Item', 'Less than 50 characters'),
            createData('Category', 'Less than 50 characters'),
            createData('Title', 'Less than 100 characters'),
            createData('Description', 'Less than 100 characters'),
            createData('Iteration Path', 'Less than 50 characters'),
            createData('Release Version', 'Less than 50 characters')
        ];

        let requiredOptionalRows = [
           
        ];

        return (
            <div className="container-fluid">
                <h1>Import Release Notes</h1>
                <h2>Step 1: Download a blank template</h2>
                <p>
                    <a href='Blank Template Release Notes.xlsx' download>Blank Template Release Notes.xlsx</a>
                </p>
                <h3>Required fields</h3>
                {createTable(requiredFieldsRows, 'Column Name', 'Guidance')}
                <br />

                <h3>Optional fields</h3>
                {createTable(requiredOptionalRows, 'Column Name', 'Guidance')}
                <br />

                <h2>Step 2: Select and upload the file for validation</h2>
                <p>After preparing the Release Notes, select the Import file, and upload.</p>
                <div className="fileuploader-container">
                    <FileUploader ref={ref => this.fileUpload = ref} selectButtonText="Select Import File" labelText="" uploadUrl={`/API/ReleaseNotes/BulkUpload`} name="file" onBeforeSend={this.onBeforeSend} onUploaded={this.onUploaded} uploadMode="useButtons" multiple={false} />
                </div>

                {this.state.importResult &&
                    <div>
                        <p title={this.state.importErrorMessage}>{this.state.importMessage}</p>
                        <br />
                        <DataGrid dataSource={this.state.releaseNotesToImport} ref={ref => this.dataGrid = ref}
                            showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 20]}
                            showInfo={true} />
                        <Column dataField="willImport" caption="Will Import?" cellRender={function (options) { return (<img width='20px' height='20px' src={options.row.data.willImport ? 'yesbutton.png' : 'nobutton.png'} />); }} defaultFilterValue={false} />
                        <Column dataField="workItemId" caption="Work Item" cellRender={function (options) { return (<div title={options.row.data.workItemIdMessage} style={{ backgroundColor: `${options.row.data.workItemIdValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.workItemId}</div>); }} />
                        <Column dataField="category" caption="Category" cellRender={function (options) { return (<div title={options.row.data.categoryMessage} style={{ backgroundColor: `${options.row.data.categoryValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.category}</div>); }} />
                        <Column dataField="title" caption="Title" cellRender={function (options) { return (<div title={options.row.data.titleMessage} style={{ backgroundColor: `${options.row.data.titleValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.title}</div>); }} />
                        <Column dataField="description" caption="Description" cellRender={function (options) { return (<div title={options.row.data.descriptionMessage} style={{ backgroundColor: `${options.row.data.descriptionValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.description}</div>); }} />
                        <Column dataField="iterationPath" caption="Iteration Path" cellRender={function (options) { return (<div title={options.row.data.iterationPathMessage} style={{ backgroundColor: `${options.row.data.iterationPathValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.iterationPath}</div>); }} />
                        <Column dataField="releaseVersion" caption="Release Version" cellRender={function (options) { return (<div title={options.row.data.releaseVersionMessage} style={{ backgroundColor: `${options.row.data.releaseVersionValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.releaseVersion}</div>); }} />
                        </DataGrid>


                    </div>
                }

                <br />
                <br />
                <h2>Step 3: Import Release Notes</h2>
                <p>After reviewing the validation results, import Release Notes in the file. If there are errors, fix and re-upload the file.</p>

                {this.state.importCount > 0 &&
                    <Button className="button" text="Import Release Notes in the File" type="success" onClick={this.ImportRecords} />
                }

                <br />
                <br />
            </div>
        );
    }
}