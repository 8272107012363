import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import Button from 'devextreme-react/ui/button';
import { Link } from 'react-router-dom';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent';
import DataGrid, { FilterRow, Column, Pager, Paging, Button as GridButton } from 'devextreme-react/ui/data-grid';

import { msalAuth } from '../../msal/MsalAuthProvider';

export class ReleaseNotesListContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            isViewingLogs: false,
            currentEmployeeId: null,
        };
    }

    customDataSource = new CustomStore({
        key: 'workItemId',
        load: (loadOptions) => {
            console.log('Load', loadOptions);

            return FetchAPIPromise('ReleaseNotes');
        }
    });

    GetData = async () => {
        var userRoles = msalAuth.getAccount().idTokenClaims.roles;

        var isUserSystemAdmin = userRoles.findIndex(element => element === 'SystemAdmin') > -1;
        //console.log('User ' + (isUserSystemAdmin ? 'IS' : 'IS NOT') + ' a Sys Admin');

        await this.setState({
            userIsSysAdmin: isUserSystemAdmin
        });
    }

    render() {


        return (
            <div className="container-fluid">
                <h1>Release Notes List</h1>

                {/*<div className="row">*/}

                {/*<div className="text-center">*/}
                {/*    <Link to="/employee">*/}
                {/*        <Button icon="plus" text="Add Employee" />*/}
                {/*    </Link>*/}
                {/*</div>*/}

                {/*<br />*/}
                {/*<div className="text-center">*/}
                {/*    <h4>- OR -</h4>*/}
                {/*</div>*/}
                {/*<br />*/}

                {this.state.userIsSysAdmin &&
                    <div>
                        <div className="text-center">
                            <Link to="/import-releaseNotes">
                                <Button icon text="Import Release Notes" />
                            </Link>
                        </div>

                        <br />
                        <div className="text-center">
                            <h4>- OR -</h4>
                        </div>
                        <br />
                    </div>
                }
                {/*</div>*/}

                <br />
                <DataGrid dataSource={this.customDataSource}
                    showBorders={true} allowColumnResizing={true}>
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <Column caption="Work Item ID" dataField="workItemId" />
                    <Column caption="Category" dataField="category" />
                    <Column caption="Title" dataField="title" />
                    <Column caption="Description" dataField="description" />
                    <Column caption="Iteration Path" dataField="iterationPath" />
                    <Column caption="Release Version" dataField="releaseVersion" />
                </DataGrid>
                <br />
            </div>
        );
    }
}