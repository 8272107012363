import React from 'react';
import BaseComponent from "../BaseComponent";
import Form, { Item, Label, ButtonItem, RequiredRule, PatternRule, StringLengthRule } from 'devextreme-react/ui/form';
import { LoadPanel } from 'devextreme-react/load-panel';

export class EmployeeEditContainer extends BaseComponent {
    static displayName = EmployeeEditContainer.name;

    constructor(props) {
        super(props);
        this.state = {
            employeeId: '',
            employeeData: {}
        };
    }

    componentDidMount = async () => {
        console.log('ComponentDidMount');
        console.log(this.props.match.params);

        const { id } = this.props.match.params

        await this.setState({ employeeId: id });

        await this.fetchData();
    }

    GetData = async () => {
        console.log(this.state.employeeId);

        const employeeData = await this.FetchAPI('Employee/' + this.state.employeeId);

        console.log(employeeData);

        await this.setState({            
                employeeData: employeeData
        });
    }

    SaveEmployee = async (e) => {
        console.log('Saving...');
        e.preventDefault();

        await this.setState({
            loading: true
        });

        console.log(this.state.employeeData);

        try {
            const result = await this.PostAPI('Employee', this.state.employeeData);

            console.log(result);

            if (result.status == 1) {
                this.props.history.push('/')
                this.props.history.push('/employee/' + result.employeeId)
            }
            else {
                alert('Failed saving Employee Info. Please try again later. If this continues, please take a screenshot and send to Luna.TechSupport@theprovidenciagroup.com.');
            }
        }
        finally {
            await this.setState({
                loading: false
            });
        }
    }

    render() {

        return (
            <div className="container">
                <h1>Edit Employee</h1>
                <form action="your-action" onSubmit={this.SaveEmployee}>
                    <Form
                        id="form"
                        colCount={1}
                        formData={this.state.employeeData}>
                        <Item itemType="group" caption="Basic Information" colCount={3}>
                            <Item dataField="firstName">
                                <RequiredRule />
                                <Label text="First Name" />
                            </Item>
                            <Item dataField="middleName">
                                <Label text="Middle Name" />
                            </Item>
                            <Item dataField="lastName">
                                <RequiredRule />
                                <Label text="Last Name" />
                            </Item>
                            <Item dataField="employeeNo">
                                <Label text="Employee No" />
                                <RequiredRule message="Employee No is required" />
                                <PatternRule message="Numbers and Letters only."
                                    pattern={/^[A-Za-z0-9]+$/} />
                                <StringLengthRule min={6} message="Employee No must have at least 6 characters." />
                            </Item>
                            <Item dataField="email">
                                <RequiredRule />
                                <Label text="Email" />
                            </Item>
                            <Item dataField="active" editorType="dxCheckBox">
                                <Label text="Active" />
                            </Item>
                        </Item>                        

                        <ButtonItem buttonOptions={{ text: 'Save', useSubmitBehavior: true }} />
                    </Form>
                </form>

                <LoadPanel
                    visible={this.state.loading} />
                <br />
            </div>
        );
    }
}