
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import Button from 'devextreme-react/ui/button';
import { Link } from 'react-router-dom';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent';
import DataGrid, { FilterRow, Column, Pager, Paging, Button as GridButton } from 'devextreme-react/ui/data-grid';

import { msalAuth } from '../../msal/MsalAuthProvider';

export class EmployeeListContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            isViewingLogs: false,
            currentEmployeeId: null,

            //userIsSysAdmin: false
        };
    }

    customDataSource = new CustomStore({
        key: 'employeeId',
        load: (loadOptions) => {
            console.log('Load', loadOptions);

            return FetchAPIPromise('Employee');
        }
    });

    GetData = async () => {
        var userRoles = msalAuth.getAccount().idTokenClaims.roles;

        var isUserSystemAdmin = userRoles.findIndex(element => element === 'SystemAdmin') > -1;
        //console.log('User ' + (isUserSystemAdmin ? 'IS' : 'IS NOT') + ' a Sys Admin');

        await this.setState({
            userIsSysAdmin: isUserSystemAdmin
        });
    }

    render() {


        return (
            <div className="container-fluid">
                <h1>Employee List</h1>

                {/*<div className="row">*/}
                    <div className="text-center">
                        <Link to="/employee">
                            <Button icon="plus" text="Add Employee" />
                        </Link>
                    </div>

                    <br />
                    <div className="text-center">
                        <h4>- OR -</h4>
                    </div>
                    <br />

                    {this.state.userIsSysAdmin &&
                        <div>
                            <div className="text-center">
                                <Link to="/import-employees">
                                    <Button icon text="Import Employees" />
                                </Link>
                            </div>

                            <br />
                            <div className="text-center">
                                <h4>- OR -</h4>
                            </div>
                            <br />
                        </div>
                    }
                {/*</div>*/}

                <br />
                <DataGrid dataSource={this.customDataSource}
                    showBorders={true} allowColumnResizing={true}
                    showRowLines={true} rowAlternationEnabled={true}>
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <Column caption="Employee ID" dataField="employeeId" dataType="string" cellRender={function (options) { return (<Link to={`/employee/${options.row.data.employeeId}`}>{options.value}</Link>); }} />
                    <Column caption="Employee Number" dataField="employeeNumber" />
                    <Column caption="First Name" dataField="firstName" />
                    <Column caption="Middle Name" dataField="middleName" />
                    <Column caption="Last Name" dataField="lastName" />
                    <Column caption="Email" dataField="email" />
                    <Column caption="Active" dataField="actualActive" defaultFilterValue={true} />
                </DataGrid>
                <br />
            </div>
        );
    }
}