import React from 'react';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import DataGrid, { FilterRow, Pager, Paging, Editing, RequiredRule, StringLengthRule, Column, Lookup, Form } from 'devextreme-react/ui/data-grid';
import { Item } from 'devextreme-react/form';
import CustomStore from 'devextreme/data/custom_store';
import * as $ from 'jquery'

const nationalityCustomStore = new CustomStore({
    key: 'id',
    load: () => {
        return FetchAPIPromise('DictionaryNationality');
    },
    remove: (key) => {
        return DeleteAPIPromise('DictionaryNationality/' + key)
            .then((result) => {
                if (result.status == -2) {
                    alert('Nationality is referenced in the system and cannot be deleted.')
                }
            });
    },
    update: (key, values) => {
        return PostAPIPromise('DictionaryNationality', values);
    },
    insert: (values) => {
        return PostAPIPromise('DictionaryNationality', values);
    }
});

export class DictionaryNationalityContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    GetData = async () => {
        var a = await this.FetchAPI('DictionaryNationality');
        console.log('nationalities', a);
    }

    onInitNewRow(e) {
        // Set active value default to true
        e.data.isActive = true;
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }


    render() {
        return (
            <div className="container">
                <h1>Nationalities</h1>
                <br />

                <DataGrid dataSource={nationalityCustomStore}
                    showBorders={true} allowColumnResizing={true}
                    showRowLines={true} rowAlternationEnabled={true}
                    keyExpr="id" onInitNewRow={this.onInitNewRow} onRowUpdating={this.onRowUpdating}>
                    <Editing mode="popup" allowAdding={true} allowUpdating={true} allowDeleting={true} />
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <Column caption="Name" dataField="name" >
                        <RequiredRule />
                        <StringLengthRule max={50} />
                    </Column>
                    <Column caption="Country" dataField="country" >
                        <RequiredRule />
                        <StringLengthRule max={50} />
                    </Column>
                </DataGrid>
            </div>
        );
    }
}
