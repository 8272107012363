
import React from 'react';
import * as moment from 'moment';

class MilestoneDetail extends React.Component {
    constructor(props) {
        console.log('Detail Props', props);
        super(props);
        this.dataSource = props.data.row.data;
    }
    render() {
        return (
            <div>
                {this.dataSource.values.map((item, index) => {

                    return (<div>{item.label}: {item.dataType == 'datetime' ? moment(item.value).format('MM/DD/YYYY HH:mm') : item.value}</div>);
                })}
            </div>
        );
    }
}

export default MilestoneDetail;