
import React from 'react';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import CustomStore from 'devextreme/data/custom_store';
import Form, { Item, GroupItem, Label, RequiredRule, StringLengthRule, EmptyItem, ButtonItem, TabbedItem, Tab, PatternRule } from 'devextreme-react/form';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import Button from 'devextreme-react/ui/button';
import DataGrid, { Column, Paging, Editing, MasterDetail, Button as GridButton, FormItem, FilterRow, Pager } from 'devextreme-react/ui/data-grid';
import { Popup } from 'devextreme-react/popup';
import { Prompt } from 'react-router-dom';
import MilestoneDetail from './MilestoneDetail';
import { Link } from 'react-router-dom';
import { msalAuth } from '../../msal/MsalAuthProvider';
import { alert, confirm } from 'devextreme/ui/dialog';




// TODO: Anum validation?
// TODO: Don't love that the label is making the radio group go vertical

export class CaseEditContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            userIsSysAdmin: false,

            casesUnsavedChanges: false,
            sponsorUnsavedChanges: false,

            caseId: null,
            caseDetails: { id: '00000000-0000-0000-0000-000000000000' },
            initialCaseDetails: {},

            sponsorDetails: {},
            initialSponsorDetails: {},

            genders: [],
            nationalities: [],
            languages: [],
            sponsorCategories: [],
            employees: [],
            facilities: [],
            destinationMetros: [],
            caseStatuses: [],
            relationships: [],
            states: [],


            paymentTypes: [{ "text": "Cash", "value": true }, { "text": "Card", "value": false }],
            paidByOptions: [{ "text": "Migrant", "value": true }, { "text": "Sponsor", "value": false }],
            uberLyftNeededOptions: [{ "text": "Yes", "value": true }, { "text": "No", "value": false }],

            isAddingMilestone: false,
            milestoneData: {},
            controlList: [],
            controlOptionList: [],

            isEditingSponsorData: false,

            binarySelections: [{ text: 'Yes', value: true }, { text: 'No', value: false }]
        };

        Date.prototype.addDays = function (days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

        Date.prototype.addYears = function (years) {
            var date = new Date(this.valueOf());
            date.setFullYear(date.getFullYear() + years);
            return date;
        }

        var current = new Date();

        var dateToMilliseconds = current.getTime();
        var addedHours = dateToMilliseconds + (3600000 * 4);
        this.TodayPlus4Hours = new Date(addedHours);

        this.OneYearAhead = current.addYears(1).addDays(-1);
        this.OneYearAgo = current.addYears(-1).addDays(1);

        this.DateOfBirthMaximum = current.addDays(-10);

        // https://js.devexpress.com/Demos/WidgetsGallery/Demo/Common/FormsAndMultiPurposeOverview/React/Light/
        this.notesOptions = { height: 200 };
    }


    //task 271
    //getMilestoneData = async () => {
    //    return this.FetchAPI('CaseMilestone/GetMilestonesByCase/' + this.state.caseId);
    //}

    milestonesDataSource = new CustomStore({
        //key: 'id',
        load: (loadOptions) => {
            //task 271
            //this.getMilestoneData().then(result => this.setState({
            //    msList: result
            //}))

            return FetchAPIPromise('CaseMilestone/GetMilestonesByCase/' + this.state.caseId);
        }
    });

    relationshipGroupDataSource = new CustomStore({
        load: (loadOptions) => {
            return FetchAPIPromise('Cases/GetRelationshipGroupMembers?caseId=' + this.state.caseId);
        }
    });

    contactLogDataSource = new CustomStore({
        load: (loadOptions) => {
            return FetchAPIPromise('CaseContactLog/' + this.state.caseId);
        }
    })

    componentDidUpdate = () => {
        //if (!this.state.casesEqual || !this.state.sponsorEqual) {
        //    window.onbeforeunload = () => true;
        //}
        //else {
        //    window.onbeforeunload = null;
        //}
    }

    beforeunload = (e) => {
        if ((JSON.stringify(this.state.initialCaseDetails) !== JSON.stringify(this.state.caseDetails)) || (JSON.stringify(this.state.initialSponsorDetails) !== JSON.stringify(this.state.sponsorDetails))) {
            console.log(e);
            e.preventDefault();
            e.returnValue = true;
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('beforeunload', this.beforeunload, true);
    }


    componentDidMount = async () => {
        console.log('ComponentDidMount');
        console.log(this.props.match.params);
        console.log(this.props.match.params.id);

        console.log('id ' + this.props.match.params.id);

        if (this.props.match.params.id != undefined) {
            await this.setState({ caseId: this.props.match.params.id });
        }

        await this.fetchData();

        window.addEventListener('beforeunload', this.beforeunload, true);
    }

    GetData = async () => {
        var userName = msalAuth.getAccount().userName;

        const employeeData = await FetchAPIPromise('Employee', '&IsActive=true');
        const currentEmployee = await this.FetchAPI('Employee/GetEmployeeByEmail/' + userName);
        const facilityData = await FetchAPIPromise('Facility', '&IsActive=true');

        var userId = currentEmployee.employeeId;

        if (this.state.caseId) { // edit a case
            var userRoles = msalAuth.getAccount().idTokenClaims.roles;
            var isUserSystemAdmin = userRoles.findIndex(element => element === 'SystemAdmin') > -1;

            var milestoneControlData = await this.FetchAPI('CaseMilestone/GetActions');

            var caseData = await this.FetchAPI('Cases/' + this.state.caseId);
            var sponsorData = await this.FetchAPI('Cases/GetSponsorInfo?caseId=' + this.state.caseId);

            console.log('case data', caseData);

            let initialCase = JSON.parse(JSON.stringify(caseData));
            let initialSponsor = JSON.parse(JSON.stringify(sponsorData));

            await this.setState({
                caseDetails: caseData,
                sponsorDetails: sponsorData,

                employees: employeeData,

                initialCaseDetails: initialCase,
                initialSponsorDetails: initialSponsor,
                userIsSysAdmin: isUserSystemAdmin,

                controlList: milestoneControlData.controlList,
                controlOptionList: milestoneControlData.controlOptionList
            });
        }
        else { // add a new case

            var currentFacilityId = null;
            if (facilityData.length == 1) {
                currentFacilityId = facilityData[0].id;
            }

            var caseData = {
                id: '00000000-0000-0000-0000-000000000000',
                caseManagerId: userId,

                homeStudyRequired: false, //Payment Type
                sponsorHouseholdFingerprintsRequired: false, //Paid By
                runaway: false, //Uber/Lyft Needed?
                facilityId: currentFacilityId
            };
            var initialCase = { id: '00000000-0000-0000-0000-000000000000' };

            await this.setState({
                caseDetails: caseData,

                employees: employeeData,

                initialCaseDetails: initialCase
            });
        }
    }

    GetDropDownData = async () => {
        const genderData = await this.FetchAPI('DictionaryGender');
        const nationalityData = await this.FetchAPI('DictionaryNationality');
        const languageData = await this.FetchAPI('DictionaryLanguage');
        const sponsorCategoryData = await this.FetchAPI('DictionarySponsorCategories');
        const caseStatusData = await this.FetchAPI('DictionaryCaseStatus');
        const relationshipData = await this.FetchAPI('DictionarySponsorRelationship');
        const stateData = await this.FetchAPI('DictionaryState');
        //const destinationMetroData = await this.FetchAPI('DictionaryDestinationMetro');
        const destinationMetroData = await FetchAPIPromise('DictionaryDestinationMetro');
        const facilityData = await FetchAPIPromise('Facility', '&IsActive=true');

        var yesNoData = [{ code: 'Yes', name: 'Yes' }, { code: 'No', name: 'No' }];

        this.setState({
            genders: genderData,
            nationalities: nationalityData,
            languages: languageData,
            sponsorCategories: sponsorCategoryData,
            caseStatuses: caseStatusData,
            relationships: relationshipData,
            states: stateData,
            facilities: facilityData,
            destinationMetros: destinationMetroData,
            yesNoList: yesNoData,
        });
    }

    sponsorFieldDataChanged = async (e) => {
        if (JSON.stringify(this.state.sponsorDetails) !== JSON.stringify(this.state.initialSponsorDetails) && !this.state.sponsorUnsavedChanges) {
            await this.setState({
                sponsorUnsavedChanges: true
            });
        }
    }

    caseFieldDataChanged = async (e) => {
        if (this.state.initialCaseDetails.dob == this.state.caseDetails.dob) {
            this.state.initialCaseDetails.age = this.state.caseDetails.age;
        }
        if (JSON.stringify(this.state.caseDetails) !== JSON.stringify(this.state.initialCaseDetails) && !this.state.casesUnsavedChanges) {
            await this.setState({
                casesUnsavedChanges: true
            });
        }

        switch (e.dataField) {
            case "dob":
                {
                    var newAge = null;

                    if (e.value) {
                        var time = new Date(e.value);
                        var year = time.getFullYear();
                        var month = time.getMonth();

                        var time2 = new Date();

                        var year2 = time2.getFullYear();
                        var month2 = time2.getMonth();

                        var ageYear = year2 - year;
                        var ageMonth = (ageYear * 12 + month2 - month) % 12;

                        if ((month2 - month) < 0) {
                            ageYear = ageYear - 1;
                        }

                        newAge = ageYear + " year(s) " + ageMonth + " month(s)";
                    }
                    else {
                        newAge = null;
                    }

                    //console.log('New Age value', newAge);

                    // await this.updateProperty('customerData', 'age', newAge);
                    e.component.updateData('age', newAge);

                    break;
                }
        }
    }

    validateCaseForm = () => {
        var initialValidate = this.editFormControl.instance.validate();

        if (!initialValidate.isValid) {
            return false;
        }

        return true;
    }

    validateSponsorForm = () => {
        var initialValidate2 = this.editFormControl2.instance.validate();

        if (!initialValidate2.isValid) {
            return false;
        }

        return true;
    }

    DeleteCase = async (e) => {
        if (await confirm("Are you sure you want to delete this Case?")) {
            //console.log('Deleting case...');
            await this.setState({
                loading: true
            });
            try {
                console.log(this.state.caseId);
                const result = await this.DeleteAPI('Cases/' + this.state.caseId);
                if (result.status == 1) {
                    this.props.history.push('/');
                    this.props.history.push('/cases');
                }
                else {
                    alert(result.message);
                }
            }
            finally {
                await this.setState({
                    loading: false
                });
            }
        }
    }

    SaveCase = async (e) => {
        //console.log('Saving...');
        //e.preventDefault();
        if (this.validateCaseForm()) {
            await this.setState({
                loading: true,
                casesUnsavedChanges: false
            });

            try {
                //var anumCheck = await this.PostAPI('Cases/AnumCheck', this.state.caseDetails);

                //if (anumCheck) {
                //    alert('This A Number already exists for another case.');
                //    return;
                //}

                console.log(this.state.caseDetails);
                const result = await this.PostAPI('Cases', this.state.caseDetails);

                //console.log(result);

                if (result.status == 1) {
                    var caseId = this.state.caseId;

                    if (this.state.caseId == undefined) {
                        caseId = result.message;
                    }

                    this.props.history.push('/');
                    this.props.history.push('/case-information/' + caseId);
                }
                else if (result.status == -2) {

                    alert(result.message);

                    //this.props.history.push('/');
                    //this.props.history.push('/case-information/' + caseId);
                }
                else {
                    //console.log(result);
                    alert('Failed saving Case Info. Please try again later. If this continues, please take a screenshot and send to Luna.TechSupport@theprovidenciagroup.com.');
                }
            }
            finally {
                await this.setState({
                    loading: false
                });
            }
        }
    }

    SaveSponsor = async (e) => {
        //console.log('Saving...');
        //e.preventDefault();
        if (this.validateSponsorForm()) {
            //console.log('Valid');
            await this.setState({
                loading: true,
                sponsorUnsavedChanges: false
            });

            //console.log(this.state.sponsorDetails);
            const result = await this.PostAPI('Cases/SaveSponsorInfo', this.state.sponsorDetails);

            //console.log(result);

            if (result.status != 1) {
                //console.log(result);
                alert('Failed saving Sponsor Info. Please try again later. If this continues, please take a screenshot and send to Luna.TechSupport@theprovidenciagroup.com.');
            }

            await this.setState({
                loading: false
            });
        }
    }

    canInsertMilestone = (e) => {
        //console.log(e.row.data.id);
        //console.log(e.row.data.id == null);

        return e.row.data.id == null;
    }

    canEditMilestone = (e) => {
        //console.log(e);

        return e.row.data.id;
    }

    canClearMilestone = (e) => {
        //console.log(e);
        return e.row.data.id && this.state.userIsSysAdmin;
    }

    hideMilestoneEdit = async () => {
        await this.setState({
            isAddingMilestone: false,
            milestoneData: {}
        })
    }

    clearMilestone = async (e) => {
        if (await confirm("Are you sure you want to clear this Milestone?", e.row.data.milestoneName)) {
            const response = await this.DeleteAPI('CaseMilestone/' + e.row.data.id);
            this.milestonesGrid.instance.refresh();
        }
    }

    addMilestone = async (e) => {
        //console.log(e);

        var milestoneData = e.row.data;

        if (milestoneData.values) {
            milestoneData.values.map((item, index) => {
                milestoneData[item.controlId] = item.value;
            });
        }

        await this.setState({
            isAddingMilestone: true,

            milestoneData: e.row.data
        });
    }

    onRowPrepared = (e) => {
        //console.log(e);

        if (e.rowType === 'data') {
            if (!e.data.values || e.data.values.length == 0) {
                e.cells[0].cellElement.classList.remove('dx-datagrid-expand');
                e.cells[0].cellElement.childNodes[0].classList.remove('dx-datagrid-group-closed');
            }
        }
    }

    onContentReady = (e) => {

    }

    SaveMilestone = async (e) => {
        var result = this.editMilestoneControl.instance.validate();

        if (result.isValid) {

            var milestoneData = this.state.milestoneData;

            milestoneData.userOffset = new Date().getTimezoneOffset() / -60;

            milestoneData.caseId = this.state.caseDetails.id;

            milestoneData.values = [];

            if (!milestoneData.id) {
                milestoneData.id = '00000000-0000-0000-0000-000000000000';
            }

            this.state.controlList.filter(item => item.milestoneId == milestoneData.milestoneId).sort((a, b) => a.order - b.order).map((control, index) => {
                milestoneData.values.push({ controlId: control.controlId, caseMilestoneId: milestoneData.id, value: milestoneData[control.controlId].toString(), file: null });
                delete milestoneData[control.controlId];
            });

            //console.log(milestoneData);
            const result = await this.PostAPI('CaseMilestone', milestoneData);

            //console.log(result);

            if (result.status == 1) {
                this.setState({
                    isAddingMilestone: false,
                    milestoneData: {}
                })

                this.milestonesGrid.instance.refresh();
            }
            else {
                //console.log(result);
                alert('Failed saving Milestone. This is usually due to Comments longer than 2,000 characters. Please shorten the Comments and try again. If this continues, please take a screenshot and send to Luna.TechSupport@theprovidenciagroup.com.');
            }
        }
    }

    viewEditSponsorDetails = (e) => {

        this.setState({
            isEditingSponsorData: true
        });
    }

    hideSponsorEdit = (e) => {
        this.editFormControl2.instance.repaint();

        this.setState({
            isEditingSponsorData: false
        });
    }

    render() {
        console.log('Case', this.state.caseDetails);
        return (
            <div>
                <div className="container-fluid">
                    <Prompt when={this.state.casesUnsavedChanges || this.state.sponsorUnsavedChanges} message="Changes you made may not be saved" />
                    <React.Fragment>
                        <div className="row">
                            <div className="ml-auto mr-3 form-inline">
                                {(this.state.userIsSysAdmin && this.state.caseDetails.id != '00000000-0000-0000-0000-000000000000') &&
                                    <Button text="Delete Case" type="danger" stylingMode="contained" onClick={this.DeleteCase} visible={this.state.userIsSysAdmin} />
                                }
                                &nbsp;<Button text="Save Case Info" type="success" stylingMode="contained" onClick={this.SaveCase} />
                            </div>
                        </div>
                        <div class="spacer"></div>
                        <Form id="form" formData={this.state.caseDetails}
                            ref={ref => this.editFormControl = ref}
                            onFieldDataChanged={this.caseFieldDataChanged} colCount={4}>
                            <GroupItem colCount={2} caption="Migrant Demographics" colSpan={2}>
                                {this.state.caseDetails.id != '00000000-0000-0000-0000-000000000000' &&
                                    <Item dataField="caseNumber" editorOptions={{ readOnly: true }} />
                                }
                                {this.state.caseDetails.id != '00000000-0000-0000-0000-000000000000' &&
                                    <Item dataField="caseStatusCode" editorType="dxSelectBox" editorOptions={{ dataSource: this.state.caseStatuses, displayExpr: 'name', valueExpr: 'code' }}>
                                        <Label text="Case Status" />
                                    </Item>
                                }
                                <Item dataField="firstName">
                                    <RequiredRule />
                                    <StringLengthRule max={50} />
                                </Item>
                                <Item dataField="middleName" >
                                    <StringLengthRule max={50} />
                                </Item>
                                <Item dataField="lastName">
                                    <RequiredRule />
                                    <StringLengthRule max={100} />

                                </Item>
                                <Item dataField="aNumber" editorOptions={{ placeholder: 'Enter A#/ EPS#/ ALT#' }}>
                                    <Label text="A#/ EPS#/ ALT#" />
                                    <RequiredRule />
                                    <PatternRule message="Please enter exactly 9 digits" pattern="^\d{9}$" />
                                    <StringLengthRule max={9} />
                                </Item>

                                <Item dataField="dob" editorType="dxDateBox" format="MM/d/yyyy" dataType="date" editorOptions={{ max: this.DateOfBirthMaximum }}>
                                    <Label text="DOB" />
                                    <RequiredRule />
                                </Item>
                                <Item dataField="age" editorOptions={{ readOnly: true }} />
                                {/*<Item dataField="epsNumber">
                            <Label text="EPS Number" />
                            <StringLengthRule max={50} />
                        </Item>
                       
                        <Item dataField="alternativeId">
                            <Label text="Alternative ID" />
                            <StringLengthRule max={50} />
                        </Item>*/}
                                <Item dataField="genderCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.genders, displayExpr: 'name', valueExpr: 'code' }}>
                                    <Label text="Gender" />
                                </Item>
                                <Item dataField="nationalityId" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.nationalities, displayExpr: 'name', valueExpr: 'id' }}>
                                    <Label text="Country of Origin" />
                                    <RequiredRule />
                                </Item>
                                <Item dataField="languageId" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.languages, displayExpr: 'name', valueExpr: 'id' }}>
                                    <Label text="Language" />
                                    <RequiredRule />
                                </Item>
                                <Item dataField="facilityId" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.facilities, displayExpr: 'name', valueExpr: 'id' }}>
                                    <Label text="Facility" />
                                    <RequiredRule />
                                </Item>
                                <Item dataField="podNumber">
                                    <Label text="NGO Supporting" />
                                    <StringLengthRule max={100} />
                                </Item>
                                <Item dataField="sponsorCategoryCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.sponsorCategories, displayExpr: 'name', valueExpr: 'code' }}>
                                    <Label text="Travel Method" />
                                    <RequiredRule />
                                </Item>
                                <Item dataField="bedNumber">
                                    <Label text="Job Code" />
                                    <RequiredRule />
                                    <StringLengthRule max={100} />
                                </Item>
                                <Item dataField="relationshipGroupID">
                                    <Label text="Relationship Group" />
                                </Item>
                                <Item dataField="religiousAffiliation">
                                    <Label text="# of Travelers" />
                                    <StringLengthRule max={100} />
                                </Item>
                                <Item dataField="pictureInPortal" editorType="dxRadioGroup" editorOptions={{ items: this.state.binarySelections, displayExpr: 'text', valueExpr: 'value' }}>
                                    <Label text="Primary Individual?" />
                                </Item>
                                <Item dataField="destinationMetro" cssClass="keyField" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.destinationMetros, displayExpr: 'destinationMetroName', valueExpr: 'destinationMetroName' }}>
                                    <Label text="Destination Metro" />
                                </Item>
                                <Item dataField="destinationMetroOther" cssClass="keyField">
                                    <Label text="Destination Metro Other" />
                                </Item>
                                {/* <Item dataField="destinationCity" cssClass="keyField">
                            <Label text="Destination City" />
                         </Item>

                        <Item dataField="destinationStateCode" cssClass="keyField" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.states, displayExpr: 'name', valueExpr: 'code' }}>
                           <Label text="Destination State" />
                        </Item>*/}
                            </GroupItem>
                            <GroupItem caption="Assignment">
                                <Item dataField="caseManagerId" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.employees, displayExpr: 'display', valueExpr: 'employeeId' }}>
                                    <Label text="Intake Specialist" />
                                    <RequiredRule />
                                </Item>
                                <Item dataField="seniorLeadId" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.employees, displayExpr: 'display', valueExpr: 'employeeId' }}>
                                    <Label text="Travel Specialist" />
                                </Item>
                                <Item dataField="leadCaseManagerId" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.employees, displayExpr: 'display', valueExpr: 'employeeId' }}>
                                    <Label text="Closeout Specialist" />
                                </Item>
                                {/*<Item dataField="shadowId" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.employees, displayExpr: 'display', valueExpr: 'employeeId' }}>*/}
                                {/*    <Label text="Shadow" />*/}
                                {/*</Item>*/}
                                <Item dataField="caseAids" editorType="dxTagBox" editorOptions={{ showSelectionControls: true, searchEnabled: true, applyValueMode: 'useButtons', dataSource: this.state.employees, displayExpr: 'display', valueExpr: 'employeeId' }}>
                                    <Label text="Facilitation Specialist" />
                                </Item>
                            </GroupItem>
                            <GroupItem cssClass="second-group">
                                <GroupItem caption="Migrant Travel Payment" >
                                    <Item dataField="homeStudyRequired" editorType="dxRadioGroup" editorOptions={{ items: this.state.paymentTypes, displayExpr: 'text', valueExpr: 'value', layout: "horizontal" }}>
                                        <Label text="Payment Type" />
                                    </Item>
                                    <Item dataField="sponsorHouseholdFingerprintsRequired" editorType="dxRadioGroup" editorOptions={{ items: this.state.paidByOptions, displayExpr: 'text', valueExpr: 'value', layout: "horizontal" }}>
                                        <Label text="Paid By" />
                                    </Item>
                                    <Item dataField="runaway" editorType="dxRadioGroup" editorOptions={{ items: this.state.uberLyftNeededOptions, displayExpr: 'text', valueExpr: 'value', layout: "horizontal" }}>
                                        <Label text="Uber/Lyft Needed?" />
                                    </Item>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem caption="Case Notes" colSpan={4}>
                                <Item dataField="caseSummary" editorType="dxTextArea" editorOptions={this.notesOptions} helpText="2,000 characters maximum">
                                    <Label text="Case Notes" />
                                    <StringLengthRule max={2000} message="2,000 characters maximum" />
                                </Item>
                            </GroupItem>
                        </Form>
                    </React.Fragment>
                </div>
                <br />
                <div className="container">
                    {this.state.caseDetails.id != '00000000-0000-0000-0000-000000000000' &&
                        <TabPanel>
                            <TabItem title="Milestones">
                                <div style={{ margin: "10px" }}>
                                    <DataGrid dataSource={this.milestonesDataSource} ref={ref => this.milestonesGrid = ref}
                                        showBorders={true} allowColumnResizing={true} onRowPrepared={this.onRowPrepared} onContentReady={this.onContentReady}
                                        showRowLines={true} rowAlternationEnabled={false} columnAutoWidth={true} wordWrapEnabled={true} >
                                        <Paging enabled={false} />
                                        <Editing allowDeleting={false} allowAdding={false} allowUpdating={true} mode="popup" />
                                        <Column dataField="milestoneName" >
                                            <FormItem visible={false} />
                                        </Column>
                                        <Column type="buttons">
                                            <GridButton text="Add" visible={this.canInsertMilestone} onClick={this.addMilestone} />
                                            <GridButton text="Edit" visible={this.canEditMilestone} onClick={this.addMilestone} />
                                            <GridButton text="Clear" visible={this.canClearMilestone} onClick={this.clearMilestone} />
                                        </Column>
                                        <Column dataField="eventDateTime" format="MM/d/yyyy HH:mm" dataType="datetime">
                                            <RequiredRule />
                                        </Column>
                                        <Column dataField="comment" />

                                        <MasterDetail enabled={true} component={MilestoneDetail} autoExpandAll={false} />
                                    </DataGrid>
                                </div>
                            </TabItem>
                            <TabItem title="Sponsor Demographics">
                                <div style={{ margin: "10px" }}>
                                    <Form formData={this.state.sponsorDetails} onFieldDataChanged={this.sponsorFieldDataChanged} ref={ref => this.editFormControl2 = ref} colCount={3}>
                                        <EmptyItem />
                                        <EmptyItem />
                                        <ButtonItem buttonOptions={{ type: "success", text: 'Save Sponsor Info', onClick: this.SaveSponsor }} />
                                        <Button text="Save Sponsor Info" type="success" stylingMode="contained" onClick={this.SaveSponsor} />
                                        <Item itemType="group" colCount={3} colSpan={3} caption="Primary Information">
                                            <Item dataField="firstName" >
                                                <Label text="First Name" />
                                            </Item>
                                            <Item dataField="middleName" >
                                                <Label text="Middle Name" />
                                            </Item>
                                            <Item dataField="lastName" >
                                                <Label text="Last Name" />
                                            </Item>

                                            {/*<Item dataField="dob" editorType="dxDateBox" format="MM/d/yyyy" dataType="date">*/}
                                            {/*    <Label text="DOB" />*/}
                                            {/*</Item>*/}
                                            <Item dataField="genderCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.genders, displayExpr: 'name', valueExpr: 'code' }}>
                                                <Label text="Gender" />
                                            </Item>
                                            <Item dataField="languageId" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.languages, displayExpr: 'name', valueExpr: 'id' }}>
                                                <Label text="Language" />
                                            </Item>

                                            <Item dataField="homePhone" >
                                                <Label text="Home Phone" />
                                            </Item>
                                            <Item dataField="mobilePhone" >
                                                <Label text="Mobile Phone" />
                                            </Item>
                                            <Item dataField="workPhone" >
                                                <Label text="Work Phone" />
                                            </Item>

                                            <Item dataField="email" >
                                                <Label text="Email" />
                                            </Item>
                                            <Item dataField="alternateEmail" >
                                                <Label text="Alternate Email" />
                                            </Item>
                                            <Item dataField="whatsAppNumber">
                                                <Label text="WhatsApp Number" />
                                            </Item>

                                            <Item dataField="relationshipCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.relationships, displayExpr: 'name', valueExpr: 'code' }}>
                                                <Label text="Relationship" />
                                            </Item>
                                            {/*<Item dataField="employer" >*/}
                                            {/*    <Label text="Employer" />*/}
                                            {/*</Item>*/}
                                            {/*<Item dataField="regionalOffice">*/}
                                            {/*    <Label text="Regional Office" />*/}
                                            {/*</Item>*/}

                                            {/*<Item dataField="hasCriminalHistory" editorType="dxRadioGroup" editorOptions={{ items: this.state.binarySelections, displayExpr: 'text', valueExpr: 'value' }}>*/}
                                            {/*    <Label text="Has Criminal History" />*/}
                                            {/*</Item>*/}
                                            {/*<Item dataField="fingerprintsRequired" editorType="dxRadioGroup" editorOptions={{ items: this.state.binarySelections, displayExpr: 'text', valueExpr: 'value' }}>*/}
                                            {/*    <Label text="Fingerprints Required" />*/}
                                            {/*</Item>*/}
                                        </Item>

                                        <Item itemType="group" colCount={3} colSpan={3} caption="Address Information">
                                            <Item dataField="homeAddress1" colSpan={2}>
                                                <Label text="Home Address 1" />
                                            </Item>

                                            <Item dataField="homeAddress2">
                                                <Label text="Home address 2" />
                                            </Item>

                                            {/*<Item dataField="homeCity" cssClass="keyField">*/}
                                            {/*    <Label text="Home City" />*/}
                                            {/*</Item>*/}

                                            {/*<Item dataField="homeStateCode" cssClass="keyField" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.states, displayExpr: 'name', valueExpr: 'code' }}>*/}
                                            {/*    <Label text="Home State" />*/}
                                            {/*</Item>*/}

                                            <Item dataField="homeZipCode">
                                                <Label text="Home Zip Code" />
                                            </Item>

                                            <Item dataField="destinationMetroId" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.destinationMetros, displayExpr: 'name', valueExpr: 'id' }}>
                                                <Label text="Destination Metro" />
                                            </Item>

                                            {/*<Item dataField="mailingAddress1" colSpan={2}>*/}
                                            {/*    <Label text="Mailing Address 1" />*/}
                                            {/*</Item>*/}

                                            {/*<Item dataField="mailingAddress2">*/}
                                            {/*    <Label text="Mailing address 2" />*/}
                                            {/*</Item>*/}

                                            {/*<Item dataField="mailingCity">*/}
                                            {/*    <Label text="Mailing City" />*/}
                                            {/*</Item>*/}

                                            {/*<Item dataField="mailingStateCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.states, displayExpr: 'name', valueExpr: 'code' }}>*/}
                                            {/*    <Label text="Mailing State" />*/}
                                            {/*</Item>*/}

                                            {/*<Item dataField="mailingZipCode">*/}
                                            {/*    <Label text="Mailing Zip Code" />*/}
                                            {/*</Item>*/}
                                        </Item>
                                    </Form>
                                </div>
                            </TabItem>
                            <TabItem title="Relationship Group">
                                <div style={{ margin: "10px" }}>
                                    <DataGrid dataSource={this.relationshipGroupDataSource} ref={ref => this.relationshipGroupDataGrid = ref}
                                        showBorders={true} allowColumnResizing={true}
                                        showRowLines={true} rowAlternationEnabled={true}>
                                        <FilterRow visible={true} />
                                        <Paging defaultPageSize={10} />
                                        <Pager showPageSizeSelector={true}
                                            allowedPageSizes={[5, 10, 20]}
                                            showInfo={true} />

                                        <Column dataField="pictureInPortal" caption="Primary Individual?" />
                                        <Column dataField="caseNumber" cellRender={function (options) { return (<Link to={`/case-information/${options.row.data.id}`} target='_top'>{options.value}</Link>); }} />
                                        <Column dataField="aNumber" caption="A Number" />
                                        <Column dataField="firstName" />
                                        <Column dataField="lastName" />
                                        <Column dataField="dob" caption="DOB" format="MM/d/yyyy" dataType="date" />
                                        <Column dataField="caseStatusName" caption="Case Status" />
                                        <Column dataField="seniorLeadDisplayName" caption="Lead Unification Specialist" />
                                        <Column dataField="leadCaseManagerDisplayName" caption="Unification Specialist" />
                                        <Column dataField="caseManagerDisplayName" caption="Intake Specialist" />
                                        <Column dataField="facilityName" caption="Facility" />
                                    </DataGrid>
                                </div>
                            </TabItem>
                        </TabPanel>
                    }

                    <LoadPanel
                        visible={this.state.loading} />

                    <br />

                    <Popup visible={this.state.isAddingMilestone} onHiding={this.hideMilestoneEdit} dragEnabled={false}
                        closeOnOutsideClick={false} width={500} height={300} showTitle={true} title={this.state.milestoneData.milestoneName}>

                        <Form formData={this.state.milestoneData} colCount={2} ref={ref => this.editMilestoneControl = ref}>
                            <Item colSpan={2} dataField="eventDateTime" editorType="dxDateBox" editorOptions={{ displayFormat: 'MM/dd/yyyy HH:mm', type: 'datetime', min: this.OneYearAgo, max: this.TodayPlus4Hours }}>
                                <RequiredRule />
                            </Item>

                            {this.state.controlList.filter(item => item.milestoneId == this.state.milestoneData.milestoneId).sort((a, b) => a.order - b.order).map((control, index) => (
                                <Item colSpan={2} isRequired={true} editorType={(control.controlType == 'yesno' || control.controlType == 'passfail' || control.controlType == 'picker') ? 'dxSelectBox' : (control.controlType == 'datetime' ? 'dxDateBox' : (control.dataType != 'text' ? 'dxNumberBox' : 'dxTextBox'))}
                                    editorOptions={{
                                        displayFormat: 'MM/dd/yyyy HH:mm',
                                        type: (control.controlType == 'datetime' ? (control.dataType == 'datetime' ? 'datetime' : 'date') : null),
                                        min: (control.controlType == 'datetime' ? (control.dataType == 'datetime' ? this.OneYearAgo : 'date') : null),
                                        max: (control.controlType == 'datetime' ? (control.dataType == 'datetime' ? this.OneYearAhead : 'date') : null),
                                        searchEnabled: (control.controlType == 'yesno' || control.controlType == 'passfail' || control.controlTpe == 'picker') ? true : false,
                                        dataSource: (control.controlType == 'yesno' ? this.state.yesNoList : (control.controlType == 'passfail' ? this.state.passFailList : this.state.controlOptionList.filter(item => item.controlId == control.controlId))),
                                        valueExpr: 'code', displayExpr: 'name'
                                    }}
                                    dataField={control.controlId}>
                                    <Label text={control.label} />
                                </Item>
                            ))}

                            <Item colSpan={2} dataField="comment" itemType="dxTextArea">
                                <StringLengthRule max={2000} />
                            </Item>

                            <ButtonItem buttonOptions={{ text: 'Save', onClick: this.SaveMilestone }} />
                            <ButtonItem buttonOptions={{ text: 'Cancel', onClick: this.hideMilestoneEdit }} />
                        </Form>
                    </Popup>
                </div>
            </div>
        );
    }
}