
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Editing, RequiredRule, StringLengthRule, Column, Lookup, Form, Popup } from 'devextreme-react/ui/data-grid';
import { Item } from 'devextreme-react/form';
import Button from 'devextreme-react/ui/button';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import { Link } from 'react-router-dom';
import * as $ from 'jquery'

const facilityDataSource = new CustomStore({
    key: 'id',
    load: () => {
        return FetchAPIPromise('Facility');
    },
    insert: (values) => {
        return PostAPIPromise('Facility', values);
    },
    remove: (key) => {
        return DeleteAPIPromise('Facility/' + key)
            .then((result) => {
                if (result.status == -2) {
                    alert('Facility is referenced in the system and cannot be deleted. Consider inactivating.')
                }
            });
    },
    update: (key, values) => {
        return PostAPIPromise('Facility', values);
    }
});

const renderGridCellAzurePortal = (data) => {
    if (data.text)
    {
        return <a href={data.text} target="_blank" rel="noreferrer noopener">Azure Portal</a>;
    } else {
        return;
    }
}

const renderGridCellM365AdminCenter = (data) => {
    if (data.text) {
        return <a href={data.text} target="_blank" rel="noreferrer noopener">M365 Admin Center</a>;
    } else {
        return;
    }
}

export class FacilityContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            states: []
        };
    }

    GetDropDownData = async () => {
        const stateData = await this.FetchAPI('DictionaryState');

        this.setState({
            states: stateData
        });
    }

    onInitNewRow(e) {
        // Set active value default to true
        e.data.isActive = true;
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    render() {
        
        return (
            <div className="container-fluid">
                <h1>Facilities</h1>
                <div>
                    <Link to="/import-facilities">
                        <Button icon text="Import Facilities" />
                    </Link>
                    <hr />
                </div>
                
                <h2>Role-Based Groups</h2>
                <ul>
                    <li>
                        <b>Case Managers:</b>&nbsp;
                        <a href="https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Overview/groupId/06176877-b49b-4b9c-b1c5-6590b6be5a43" target="_blank">Azure Portal</a>,&nbsp;
                        <a href="https://admin.microsoft.com/Adminportal/Home?source=applauncher#/groups/:/GroupDetails/06176877-b49b-4b9c-b1c5-6590b6be5a43" target="_blank">M365 Admin Center</a>
                    </li>
                    <li>
                        <b>System Admins:</b>&nbsp;
                        <a href="https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Overview/groupId/c3f3bbcd-2a8d-472a-87b8-c47b02ff61ee" target="_blank">Azure Portal</a>,&nbsp;
                        <a href="https://admin.microsoft.com/Adminportal/Home?source=applauncher#/groups/:/GroupDetails/c3f3bbcd-2a8d-472a-87b8-c47b02ff61ee" target="_blank">M365 Admin Center</a>
                    </li>
                </ul>

                <h2>Facility-Based Groups</h2>
                <ul>
                    <li>
                        <b>View All Facilities:</b>&nbsp;
                        <a href="https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Overview/groupId/7211c9f3-a1e7-45b9-9236-a74bf1826224" target="_blank">Azure Portal</a>,&nbsp;
                        <a href="https://admin.microsoft.com/Adminportal/Home?source=applauncher#/groups/:/GroupDetails/7211c9f3-a1e7-45b9-9236-a74bf1826224" target="_blank">M365 Admin Center</a>
                    </li>
                </ul>

                <h2>App Roles and Assignments</h2>
                <ul>
                    <li>
                        <a href="https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/AppRoles/appId/512a53de-4265-414a-bd89-5b933d315ebf/isMSAApp/" target="_blank">Azure App Registration > App roles</a>
                    </li>
                    <li>
                        <a href="https://portal.azure.com/#view/Microsoft_AAD_IAM/ManagedAppMenuBlade/~/Users/objectId/5eaf2dd5-79aa-493b-9626-cf8fbf1d1c70/appId/512a53de-4265-414a-bd89-5b933d315ebf" target="_blank">Azure Enterprise app > Users and groups</a>
                    </li>
                </ul>

                <DataGrid dataSource={facilityDataSource}
                    showBorders={true} allowColumnResizing={true}
                    showRowLines={true} rowAlternationEnabled={true}
                    keyExpr="id" onInitNewRow={this.onInitNewRow} onRowUpdating={this.onRowUpdating}>
                    <Editing mode="popup" allowAdding={true} allowUpdating={true} allowDeleting={true}>
                        <Popup title="Facility Edit" showTitle={true} height={400} />
                        <Form>
                            <Item itemType="group" colCount={3} colSpan={2}>
                                <Item dataField="name" />
                                <Item dataField="address1" />
                                <Item dataField="address2" />

                                <Item dataField="city" />
                                <Item dataField="stateCode" />
                                <Item dataField="zipCode" />

                                <Item dataField="azureResourceId" caption="Azure Resource" />
                                <Item dataField="azureAppRoleId" caption="Azure App Role" />
                                <Item dataField="region" caption="Region" />
                                <Item dataField="type" caption="Type" />
                                <Item dataField="orrShelterName" caption="ORR Shelter Name" />
                                <Item dataField="orrPortalId" caption="ORR Portal Id" />

                                {/*<Item dataField="detlocCode" />*/}
                                <Item dataField="isActive" />
                            </Item>
                        </Form>
                    </Editing>

                    <FilterRow visible={true} />
                    <Paging defaultPageSize={25} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[25, 50, 100]}
                        showInfo={true} />
                    
                    <Column caption="Name" dataField="name">
                        <RequiredRule />
                        <StringLengthRule max={200} />
                    </Column>
                    <Column type="buttons" width={110}>
                        <Button name="edit" />
                        <Button name="delete" />
                    </Column>
                    <Column caption="Azure Portal" dataField="azurePortalUrl" cellRender={renderGridCellAzurePortal}>
                    </Column>
                    <Column caption="M365 Admin Center" dataField="m365AdminCenterUrl" cellRender={renderGridCellM365AdminCenter}>
                    </Column>
                    <Column caption="Region" dataField="region">
                        <StringLengthRule max={100} />
                    </Column>
                    <Column caption="Type" dataField="type">
                        <StringLengthRule max={100} />
                    </Column>
                    <Column caption="Address" dataField="address1">
                        <RequiredRule />
                        <StringLengthRule max={100} />
                    </Column>
                    <Column caption="Address 2" dataField="address2" visible={true} >
                        <StringLengthRule max={100} />
                    </Column>
                    <Column caption="City" dataField="city">
                        <RequiredRule />
                        <StringLengthRule max={50} />
                    </Column>
                    <Column caption="State" dataField="stateCode">
                        <RequiredRule />
                        <Lookup allowClearing={true} dataSource={this.state.states} displayExpr="code" valueExpr="code" /> 
                    </Column>
                    <Column dataField="zipCode" visible={true} >
                        <RequiredRule />
                        <StringLengthRule max={10} />
                    </Column>
                    <Column caption="DETLOC" dataField="detlocCode" visible={false}>
                        <RequiredRule />
                        <StringLengthRule max={20} />
                    </Column>
                    <Column caption="Azure Resource" dataField="azureResourceId" visible={false}>
                        <StringLengthRule max={100} />
                    </Column>
                    <Column caption="Azure App Role" dataField="azureAppRoleId">
                        <StringLengthRule max={100} />
                    </Column>
                    <Column caption="ORR Shelter Name" dataField="orrShelterName">
                        <StringLengthRule max={200} />
                    </Column>
                    <Column caption="ORR Portal Id" dataField="orrPortalId">
                        <StringLengthRule max={200} />
                    </Column>
                    <Column caption="Active" dataField="isActive" defaultFilterValue={true}/>
                </DataGrid>
            </div>
        );
    }
}