
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Editing, RequiredRule, StringLengthRule, Column } from 'devextreme-react/ui/data-grid';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import * as $ from 'jquery'

const destinationMetroDataSource = new CustomStore({
    key: 'id',
    load: () => {
        return FetchAPIPromise('DictionaryDestinationMetro');
    },
    insert: (values) => {
        return PostAPIPromise('DictionaryDestinationMetro', values);
    },
    remove: (key) => {
        return DeleteAPIPromise('DictionaryDestinationMetro/' + key)
            .then((result) => {
                if (result.status == -2) {
                    alert('Destination Metro is referenced in the system and cannot be deleted.')
                }
            });
    },
    update: (key, values) => {
        return PostAPIPromise('DictionaryDestinationMetro', values);
    }
});

export class DictionaryDestinationMetroContainer extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {};
    }

    onInitNewRow(e) {
        // Set active value default to true
        e.data.isActive = true;
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    render() {
        return (
            <div className="container">
                <h1>Destination Metros</h1>
                <br />

                <DataGrid dataSource={destinationMetroDataSource}
                    showBorders={true} allowColumnResizing={true}
                    showRowLines={true} rowAlternationEnabled={true}
                    keyExpr="id" onInitNewRow={this.onInitNewRow} onRowUpdating={this.onRowUpdating}>
                    <Editing mode="popup" allowAdding={true} allowUpdating={true} allowDeleting={true} />

                    <FilterRow visible={true} />
                    <Paging defaultPageSize={20} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />

                    <Column caption="Name" dataField="destinationMetroName">
                        <RequiredRule />
                        <StringLengthRule max={50} />
                    </Column>
                    
                </DataGrid>


            </div>
            );
    }

}